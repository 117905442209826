import React from 'react'

import Layout from '../components/layout'
import Img from '../components/img'
import FeatureHeadingSection from '../components/feature-heading-section'
import StripSection from '../components/strip-section'

import intelligentTestRunnerIcon from '../images/features/intelligent-test-runner-icon.svg'

const IntelligentTestRunnerPage = () => {
  return (
    <Layout className="intelligent-test-runner">
      <FeatureHeadingSection iconSrc={intelligentTestRunnerIcon}>
        Intelligent Test Runner
      </FeatureHeadingSection>

      <section className="feature centered">
        <div className="description">
          <h2>Protect Your Developers Time and Dramatically Cut CI Costs</h2>

          <p>Reduce testing times by up to 98%</p>
        </div>

        <div className="illustration">
          <Img id="intelligentTestRunnerCutCosts" />
        </div>
      </section>

      <section className="feature centered">
        <div className="description">
          <h2>You’re in Control of How You Test</h2>
        </div>

        <div className="columns">
          <div className="illustration">
            <Img id="intelligentTestRunnerBranches" />
            <p>Choose which branches to enable Intelligent Test Runner</p>
          </div>
          <div className="illustration">
            <Img id="intelligentTestRunnerRetries" />
            <p>
              Automatic retries at the test level means less time wasted on
              retrying builds and increased confidence in knowing whether your
              test is broken or flaky
            </p>
          </div>
        </div>
      </section>

      <StripSection className="setup">
        <div className="description">
          <h2>Easy To Use</h2>
          <p className="note">
            The Intelligent Test Runner is enabled in specific branches of your
            repository. For example, to enable it for all branches other than{' '}
            <strong>master</strong>, set the following environment variables:
          </p>
        </div>

        <div className="instructions-block">
          SCOPE_CODE_PATH_ENABLED="<strong>true</strong>"
          <br />
          SCOPE_RUNNER_ENABLED="<strong>true</strong>"
          <br />
          SCOPE_RUNNER_EXCLUDE_BRANCHES="<strong>master</strong>"
        </div>

        <p>
          Note that Code Path must also be enabled in order for the Intelligent
          Test Runner to work. Check out the agent configuration documentation
          for more information.
        </p>
      </StripSection>
    </Layout>
  )
}

export default IntelligentTestRunnerPage
